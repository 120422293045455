import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

export const ErrorMsgDialog = ({ open, toggleErrorMsg }) => {

    return (
        <Modal isOpen={open} centered={true} size="md">
            <ModalHeader></ModalHeader>
            <ModalBody>
                Sorry, Maximum allowed characters exceeded !<br />
                Please Re-visit your answer
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={() => toggleErrorMsg()}>
                    Ok
                </Button>
            </ModalFooter>
        </Modal>
    );
};
