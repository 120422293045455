import searchIcon from "../../../assets/img/search_icon.png";
import { useImmer } from "use-immer";
import { useOpenBookScopeContext } from "../../../master";
import appConfig from "../../../appConfig.json";
import { getPlainText, getText } from "../utils/bookUtils";

export const Search = () => {
    const { openBookState, setOpenBookState } = useOpenBookScopeContext();
    const [inputVal, setInputVal] = useImmer({
        val: ""
    });
    const handleKeyPress = (e) => {
        if (e.key === "Enter") {
            let searchResults = [];
            const { bookData } = openBookState;
            for (let i = 0; i < bookData.chapters.length; i++) {
                const results = extractResults(bookData.chapters[i].content, inputVal.val);
                if (results.length > 0) {
                    searchResults.push({
                        chapterName: `Chapter ${i + 1}`,
                        chapterIndex: i,
                        searchKeyWord: inputVal.val,
                        results
                    })
                }
            }
            setOpenBookState((draft) => {
                draft.searchResults = searchResults;
                draft.chapterIndex = undefined;
            });
        }
    }

    const extractResults = (contents, keyword) => {
        const results = [];
        const regex = new RegExp(`${keyword}[^<]*`, "gi");

        for (const content of contents) {
            if (appConfig.searchableTypes.includes(content.type)) {
                const matches = getText(content).match(regex);
                if (matches) {
                    for (const match of matches) {
                        const resultText = getPlainText(match).substr(0, 120);
                        results.push(resultText);
                    }
                }
            }
        }
        return results;
    }
    return (
        <>
            <div className="input-group mb-3 search-bar">
                <div className="input-group-prepend">
                    <span className="input-group-text search-icon">
                        <img src={searchIcon} alt="search icon" />
                    </span>
                </div>
                <input type="text" className="form-control search-input" placeholder="Search..." aria-label="Search" aria-describedby="basic-addon1" onChange={(e) => setInputVal((draft) => { draft.val = e.target.value; })} onKeyDown={handleKeyPress} />
            </div>
        </>
    );
}


