import { MainView } from "..";

export const OpenBookMain = () => {
  return (
    <>
      <section className="content-wrapper">
        <div className="container">
          <div className="row">
            <MainView />
          </div>
        </div>
      </section>
    </>
  );
};
