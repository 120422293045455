import appConfig from "../../../appConfig.json";
export const getText = (chapterItem) => {
    let itemText = "";
    if (appConfig.searchableTypes.includes(chapterItem.type)) {
        if (appConfig.searchableTypes.includes("list") && chapterItem.type == "list") {
            itemText = chapterItem.items.map((i) => i.text).join(" ");
        } else {
            itemText = chapterItem.text;
        }
    }
    return itemText;
}

export const getPlainText = (text) => {
    return text.replace(/<[^>]*>/g, "");
}