import { OpenBook } from "../modules";
import React from "react";
import { Route, Routes } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { OpenBookScope } from "./contexts/OpenBookScope";
import "../assets/styles/styles.css";
import { Footer, Header } from ".";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      retry: false,
    },
  },
});

export const Master = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <OpenBookScope>
        <Header />
        <Routes>
          <Route path="*" exact element={<OpenBook />} />
        </Routes>
        <Footer/>
      </OpenBookScope>
    </QueryClientProvider>
  );
};
