import { Route, Routes } from "react-router-dom";
import { OpenBookMain } from "./container/OpenBookMain";

export const OpenBook = () => {
  return (
    <>
      <Routes>
        <Route path="*" element={<OpenBookMain />} />
      </Routes>
    </>
  );
};
