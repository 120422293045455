import { useOpenBookScopeContext } from "../.";
import nergyLogo from "../../assets/img/360logoVidya.svg";

export const Header = () => {
  const { task } = useOpenBookScopeContext();
  return (
    <>
      <header className="main-header">
        <div className="container">
          <div className="head-wrap">
            <div className="header-content">
              <img src={nergyLogo} alt="nergy vidya" />
            </div>
          </div>
        </div>
      </header>
    </>
  );
};
