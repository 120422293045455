
import React, { useState, useEffect } from 'react';

export const ScrollToTop = ({ scrollableDivRef }) => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (scrollableDivRef.current.scrollTop > 1500) {
                setIsVisible(true);
            } else {
                setIsVisible(false);
            }
        };
        scrollableDivRef.current.addEventListener('scroll', handleScroll);
        return () => scrollableDivRef.current.removeEventListener('scroll', handleScroll);
    }, [scrollableDivRef]);

    const scrollToTop = () => {
        scrollableDivRef.current.querySelector(`.book-container`).scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    };

    return (
        isVisible && (
            <div onClick={scrollToTop} className='scroll-to-top'>
                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="none" viewBox="0 0 32 32" ><path fill="currentColor"  d="M15.192 8.906a1.143 1.143 0 0 1 1.616 0l5.143 5.143a1.143 1.143 0 0 1-1.616 1.616l-3.192-3.192v9.813a1.143 1.143 0 0 1-2.286 0v-9.813l-3.192 3.192a1.143 1.143 0 1 1-1.616-1.616z"></path></svg>
            </div>
        )
    );
};

