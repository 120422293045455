import React, { useEffect } from "react";
import { useImmer } from "use-immer";
import { useCourseTask } from "../hooks/useCourseTask";
import appConfig from "../../appConfig.json";

export const OpenBookContext = React.createContext();
export const OpenBookScope = (props) => {
    const {
        data,
        isError,
        isLoading,
        setValidate,
        setBookUrlEnabled,
        bookData,
        validateResponse,
        validateLoading,
        bookLoading
    } = useCourseTask();
    const [openBookState, setOpenBookState] = useImmer({
        answers: [],
        chapterIndex: undefined,
        bookData: undefined,
        qn: undefined,
        isChapterSelected: false,
        selectedChapter: {
            chapterHead: "",
            chapterDetails: "",
            chapterNo: ""
        },
        isSubmitted: false,
        searchResults: undefined,
        scrollToWord: undefined,
        conclusion: undefined,
        maxCharLimit: appConfig.maxCharLimit,
        provisionsCount: 0,
        setValidate,
        validateResponse: undefined,
        validateLoading: false,
        bookLoading: false
    });

    useEffect(() => {
        if (!isLoading && !isError && data?.data?.answerkey?.data) {
            setOpenBookState((draft) => {
                const { bookUrl, question, analysis, conclusions, book } = data.data.answerkey.data;
                const { characterLimit, provisionsCount } = data.data.answerkey.frontend_Validation;
                draft.qn = { question, analysis, conclusions };
                draft.maxCharLimit = characterLimit;
                draft.provisionsCount = provisionsCount;
                if (Array.isArray(bookUrl) && bookUrl.length) {
                    draft.bookLoading = true;
                    setBookUrlEnabled((draft) => { draft.url = bookUrl[0].sectionUrl; });
                } else if (book) {
                    draft.bookData = book;
                }
            });
        }
    }, [isLoading, isError, data?.data, setOpenBookState]);


    useEffect(() => {
        setOpenBookState((draft) => {
            draft.validateResponse = validateResponse;
            draft.validateLoading = validateLoading;
        });

    }, [ validateResponse, validateLoading, setOpenBookState]);


    useEffect(() => {
        if (bookData) {
            setOpenBookState((draft) => {
                draft.bookData = bookData.data.book;
                draft.bookLoading = bookLoading;
            });
        }
    }, [bookData, bookLoading]);

    return (
        <OpenBookContext.Provider
            value={{
                openBookState,
                setOpenBookState
            }}
        >
            {isLoading && <div className="spinner-container"><div className="spinner-border" role="status"></div></div>}
            {!isLoading && data?.status !== 200 && <div className="no-data-container">
                <h1>No Data Found</h1>
                <p>We couldn't find any data to display. Please try again later.</p>
            </div>}
            {!isLoading && data?.status === 200 && props.children}
        </OpenBookContext.Provider>
    );
};
