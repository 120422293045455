import { useOpenBookScopeContext } from "../.";

export const Footer = () => {
  const { openBookState, setOpenBookState } = useOpenBookScopeContext();
  const getCharCount = () => {
    return openBookState.answers.map(ans => ans.text).reduce((total, currentString) => {
      return total + currentString.length;
    }, 0);
  }

  const prevChapter = () => {
    setOpenBookState((draft) => {
      if (draft.chapterIndex === 0) return
      draft.chapterIndex = draft.chapterIndex - 1;
    })
  }
  const nextChapter = () => {
    setOpenBookState((draft) => {
      if (draft.chapterIndex === draft.bookData.chapters.length - 1) return
      draft.chapterIndex = draft.chapterIndex + 1;
    })
  }

  const submitAnswer = () => {
    setOpenBookState((draft) => {
      draft.isSubmitted = true;
    })
  }
  return (
    <>
      <footer className="main-footer">
        <div className="container">
          <div className="footer-wrap">
            <div className="footer-content">
              <div className="col-md-1 char-count">{getCharCount()}/{openBookState.maxCharLimit}</div>
              <div className="col-md-1"></div>
              <div className="col-md-1"></div>
              <div className="col-md-4">
                <button className="validate-btn" onClick={submitAnswer}>Validate</button>
              </div>
              <div className="col-md-5 chapter-navigation">
                <span onClick={prevChapter}> &lt; Previous Chapter</span>
                <span className="chapter-nav-seperator"></span>
                <span onClick={nextChapter}>Next Chapter &gt; </span>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
