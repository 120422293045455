import React, { useEffect } from "react";
import { Modal } from "reactstrap";
import { useImmer } from "use-immer";
import { useOpenBookScopeContext } from "../../../master";

export const SubmitMsg = () => {
    const { openBookState, setOpenBookState } = useOpenBookScopeContext();
    const [ans, setAns] = useImmer({
        correct: [],
        conclusionErr: false
    });

    useEffect(() => {
        validateAnswers();
    }, []);


    const validateAnswers = () => {
        setOpenBookState((draft) => {
            draft.validateLoading = true;
        });
        openBookState.setValidate((draft) => {
            draft.backend_Validation = {
                provisions: openBookState.answers.map(ans => ans.text),
                conclusion: openBookState.conclusion?.uniqueId
            };
            draft.isSubmit = false;
        });
    }

    useEffect(() => {
        if (!openBookState.validateLoading && openBookState.validateResponse) {
            setAns((draft) => {
                draft.correct = openBookState.validateResponse?.answerOrders;
                draft.conclusionErr = openBookState.validateResponse?.errors.some(err => {
                    return err.key.includes('conclusion');
                });
            });
        }
    }, [openBookState.validateLoading, openBookState.validateResponse]);



    const checkAllValid = () => {
        const cErr = openBookState.qn.conclusions ? openBookState.conclusion && !ans.conclusionErr : true
        if (ans.correct.length === openBookState.provisionsCount && cErr) {
            return true;
        }
        return false;
    }

    let conclusionCheck = openBookState.qn.conclusions;

    return (
        <Modal isOpen={true} centered={true} size="md">
            {openBookState.validateLoading &&
                <div className="modal-box pb-4">
                    <div className="validate-loading">
                        <div className="spinner-border" role="status"></div>
                    </div>
                </div>}
            {!openBookState.validateLoading && <div>
                <div className="modal-box pb-4">
                    <div className="modal-bg" />
                    <div className="modal-content-block">
                        <p>{ans.correct.length} out of {openBookState.provisionsCount} provisions are correct!</p>
                        {(conclusionCheck && !openBookState.conclusion) && <p>Please select conclusion</p>}
                        {(conclusionCheck && openBookState.conclusion && ans.conclusionErr) && <p>Incorrect conclusion!</p>}
                        <h2>Take A Moment To Breath!</h2>
                    </div>
                    <div className="modal-footer-block text-center">
                        {
                            ((conclusionCheck && !openBookState.conclusion) || !checkAllValid()) &&
                            <button
                                className="md-round-button retry-button"
                                onClick={() => {
                                    setOpenBookState((draft) => {
                                        draft.isSubmitted = false;
                                    })
                                }}
                            >
                                Retry
                            </button>}

                        <button
                            disabled={!checkAllValid()}
                            className="md-round-button"
                            onClick={() => {
                                openBookState.setValidate((draft) => {
                                    draft.backend_Validation = {
                                        provisions: openBookState.answers.map(ans => ans.text),
                                        conclusion: (openBookState.qn.conclusions) ? openBookState.conclusion?.uniqueId : undefined
                                    };
                                    draft.isSubmit = true;
                                })
                            }}
                        >
                            Submit!
                        </button>
                    </div>
                </div>
            </div>}
        </Modal>
    );
};
