import React, { useEffect } from 'react';
import selectAns from "../../../assets/img/selectAns.png";
import { useOpenBookScopeContext } from "../../../master";

import { Tooltip } from 'react-tooltip';
import parse from "html-react-parser";
import { DeleteMsg } from './DeleteMsg';
import { ConclusionMsg } from './ConclusionMsg';
import { useImmer } from "use-immer";
import { BsPencilSquare } from 'react-icons/bs';
import { getPlainText } from '../utils/bookUtils';

export const Ans = ({ Qn }) => {
    const { openBookState, setOpenBookState } = useOpenBookScopeContext();
    const [deleteMsg, setDeleteMsg] = useImmer({
        open: false,
        index: undefined
    });
    const [conclusionMsg, setConclusionMsg] = useImmer({
        open: false,
        msg: undefined
    });

    useEffect(() => {
        setConclusionMsg((draft) => {
            draft.msg = openBookState.conclusion?.text;
        });
    }, [openBookState.conclusion]);

    useEffect(() => {
        if (openBookState?.validateResponse) {
            const { answerOrders } = openBookState?.validateResponse;
            const ansClues = openBookState.answers.map((ans, index) => {
                return { ...ans, clue: answerOrders.includes(index) };
            });
            const rightOnes = answerOrders.map(val => ansClues[val]);
            const wrongOnes = ansClues.filter((val, index) => !answerOrders.includes(index));
            setOpenBookState((draft) => {
                draft.answers = wrongOnes.concat(rightOnes);
            });
        }
    }, [openBookState?.validateResponse?.answerOrders]);

    const deleteAnswer = () => {
        setOpenBookState((draft) => {
            draft.answers.splice(deleteMsg.index, 1);
        });
        setDeleteMsg({ open: false, index: undefined });
    }

    const reviewAnswer = (id) => {
        setOpenBookState((draft) => {
            const ans = draft.answers.find(ans => ans.id === id);
            ans.review = (ans.hasOwnProperty("review")) ? !ans.review : true;
        });
    }

    const answersDisplay = <ul>
        {openBookState.answers
            .map((ans, index) => {
                let classList = "answers-selected";
                if (ans.hasOwnProperty("clue") && ans.clue === false) {
                    classList += " line-through";
                }
                if (ans.hasOwnProperty("review") && ans.review === true) {
                    classList += " reviwed";
                }
                return (
                    <li key={ans.id} id={"actions" + ans.id} className={classList}>
                        {ans.text.split(" ").map((word, index) => (<span key={index}>{parse(getPlainText(word)) + " "}</span>))}
                        <Tooltip anchorSelect={"#actions" + ans.id} clickable className='tooltip-container'>
                            <span className='tooltip-btn' onClick={() => { reviewAnswer(ans.id) }}>Review</span>
                            <span className='tooltip-divider'> | </span>
                            <span className='tooltip-btn' onClick={() => { setDeleteMsg({ open: true, index }) }}>Delete</span>
                        </Tooltip>
                    </li>

                )
            })}
    </ul>;

    return (
        <div className='answer-container'>
            <div className={Qn.conclusions ? 'answer-top-section' : 'answer-top-section-no-conclusion'}>
                {Qn.analysis && <div className='answer-item'>
                    <div className='answer-title'>Analysis</div>
                    <div>{Qn.analysis}</div>
                    <hr />
                </div>}
                <div className='answer-item'>
                    <div className='answer-title'>Provisions/Principles</div>
                    <div>
                        {openBookState.answers.length === 0 ? <img src={selectAns} alt="Select Answer Here" className="select-ans" /> : answersDisplay}
                    </div>

                </div>
            </div>
            {Qn.conclusions && <div>
                {conclusionMsg.msg === undefined && <div className='answer-item bottom-dashed-section'> <div className='conclusion-btn' onClick={() => setConclusionMsg({ open: true })}>
                    + Add Conclusion
                </div> </div>}
                {conclusionMsg.msg !== undefined && <div className='answer-item'>
                    <hr />
                    <div className='answer-title'>
                        Conclusion
                        <span className='edit-icon' onClick={() => setConclusionMsg((draft) => { draft.open = true; })}><BsPencilSquare size={15} /></span>
                    </div>
                    <div className='conclusion-msg'>{conclusionMsg.msg}</div>
                </div>}
            </div>}
            {deleteMsg.open && <DeleteMsg open={deleteMsg.open} deleteAnswer={deleteAnswer} cancel={() => setDeleteMsg({ open: false, index: undefined })} />}
            {conclusionMsg.open && <ConclusionMsg Qn={Qn} setConclusionMsg={setConclusionMsg} cancel={() => setConclusionMsg({ open: false, msg: conclusionMsg.msg })} />}
        </div>
    );
};

