import React from "react";
import { useOpenBookScopeContext } from "../../../master";
import { Modal } from "reactstrap";
import { useImmer } from "use-immer";

export const ConclusionMsg = ({ Qn, setConclusionMsg, cancel }) => {
    const { openBookState, setOpenBookState } = useOpenBookScopeContext();
    const [selected, setSelected] = useImmer({
        index: undefined
    });

    return (
        <Modal isOpen={true} centered={true} size="md">
            <div>
                <div className="modal-conclusion-box pb-4">
                    <div className="modal-conclusion-content-block">
                        <h2>Select Your Conclusion</h2>
                        <div className="conclusion-list">
                            {Qn.conclusions.map((conclusion, index) => {
                                return (
                                    <div key={index} className={`conclusion-item ${selected.index === conclusion.uniqueId ? "item-selected" : ""}`} onClick={() => setSelected((draft) => { draft.index = conclusion.uniqueId; })}>{conclusion.text}</div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="modal-conclusion-footer-block text-center">
                        <button
                            className="md-round-button button-cancel"
                            onClick={cancel}
                        >
                            Cancel
                        </button>
                        <button
                            className="md-round-button button-success"
                            onClick={() => { 
                                setOpenBookState((draft) => { draft.conclusion = Qn.conclusions.find(i => i.uniqueId === selected.index) });
                                setConclusionMsg((draft) => { draft.open = false; });
                             }}
                        >
                            Choose
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
