import { Search, Book } from ".";
import { useOpenBookScopeContext } from "../../../master";
import { SubmitMsg } from "./SubmitMsg";
import { useEffect, useRef } from "react";
import { useImmer } from "use-immer";
import { Ans } from "./Ans";
import { ScrollToTop } from "./ScrollToTop";

export const MainView = () => {
    const { openBookState } = useOpenBookScopeContext();
    const [showSubmitMsg, setShowSubmitMsg] = useImmer({
        open: false
    });
    const scrollDivRef = useRef(null);

    useEffect(() => {
        setShowSubmitMsg((draft) => {
            draft.open = openBookState.isSubmitted
        });
    }, [openBookState.isSubmitted]);

    return (
        <>
            <div className="col-md-5 answer-section">
                {openBookState.qn && <Ans Qn={openBookState.qn} />}
            </div>

            <div className="col-md-7 book-content scrollable-section" ref={scrollDivRef}>
                <Search />
                <Book />
                <ScrollToTop scrollableDivRef={scrollDivRef} />
            </div>
            {showSubmitMsg.open && openBookState.qn && <SubmitMsg />}
        </>
    );
}