import React from "react";
import { Modal } from "reactstrap";

export const DeleteMsg = ({ deleteAnswer, cancel }) => {

    return (
        <Modal isOpen={true} centered={true} size="md">
            <div>
                <div className="modal-delete-box">
                <div className="modal-delete-bg" />
                    <div className="modal-delete-content-block">
                        <h2>Confirm Delete</h2>
                        <p>Are you sure you want to delete?</p>
                    </div>
                    <div className="modal-footer-delete-block text-center">
                        <button
                            className="md-round-button button-cancel"
                            onClick={cancel}
                        >
                            Cancel
                        </button>
                        <button
                            className="md-round-button button-success"
                            onClick={deleteAnswer}
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};
