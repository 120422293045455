import axios from "axios";
import * as Sentry from "@sentry/react";

export const getCourseTask = async ({ taskId }, headers) => {
    const result = await axios
        .get(`/CourseTasks/Student/${taskId}`, {
            headers,
        })
        .then((res) => res)
        .catch((error) => {
            if (error.response) {
                const statusCode = error.response.status;
                if (statusCode !== 400 && statusCode !== 401) {
                    Sentry.captureException(error);
                }
            }
            return error?.response;
        });
    return result;
};

export const validateCourseTask = async ({ validate, taskId, isSubmit, headers }) => {
    const result = await axios.post(
        `/CourseTasks/Validate/${taskId}/${isSubmit}`,
        validate,
        {
            headers,
        }
    );
    return result.data;
};

export const getBookJson = async ({ url }, headers) => {
    const result = await axios
        .get(url, {
            headers,
        })
        .then((res) => res)
        .catch((error) => {
            if (error.response) {
                const statusCode = error.response.status;
                if (statusCode !== 400 && statusCode !== 401) {
                    Sentry.captureException(error);
                }
            }
            return error?.response;
        });
    return result;
};
